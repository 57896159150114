<template>
  <div class="contents">
    <div class="title">
      <h1>會員服務</h1>
      <span>MEMBERSHIP SERVICE</span>
    </div>
    <dealer-information :map="map" />
    <el-row
      :gutter="15"
      class="icon-list"
    >
      <el-col :md="12">
        <div @click="clickPersonalInformation">
          <el-card class="card-4">
            <span />
            <div class="name">
              <h1>個人資訊</h1>
              <p>Personal information</p>
            </div>
          </el-card>
        </div>
      </el-col>
      <el-col :md="12">
        <div @click="clickServiceApply()">
          <el-card class="card-3">
            <span />
            <div class="name">
              <h1>服務申請</h1>
              <p>Service application</p>
            </div>
          </el-card>
        </div>
      </el-col>
      <!-- <el-col :md="12">
        <div @click="clickTheQuery">
          <el-card class="card-2">
            <span />
            <div class="name">
              <h1>點數查詢</h1>
              <p>Point inquiry</p>
            </div>
          </el-card>
        </div>
      </el-col> -->
      <el-col :md="12">
        <div @click="clickWaterQuantity">
          <el-card class="card-7">
            <span />
            <div class="name">
              <h1>居家水質查詢</h1>
              <p>Water Quantity</p>
            </div>
          </el-card>
        </div>
      </el-col>
      <el-col :md="12">
        <div @click="clickServiceHistory">
          <el-card class="card-6">
            <span />
            <div class="name">
              <h1>服務記錄查詢</h1>
              <p>Service History</p>
            </div>
          </el-card>
        </div>
      </el-col>
      <el-col :md="12">
        <div @click="clickTermsAndConditions">
          <el-card class="card-5">
            <span />
            <div class="name">
              <h1>會員權益聲明</h1>
              <p>Terms And Conditions</p>
            </div>
          </el-card>
        </div>
      </el-col>
    </el-row>
    <!-- <div class="btn" @click="clickExit">登出</div> -->
    <guide-four
      :is-dialog-show="isShowFour"
      :exit="true"
      :confirm-click="confirmClick"
      :no-click="noClick"
    />
    <Loading :show="isShowLoadging" />
  </div>
</template>

<script>
import DealerInformation from '@/components/DealerInformation'
import GuideFour from '@/components/PopUp/Guide_4'
import { actionLog, getMaintenanceDistribution } from './../api/api'
import storage from './../storage'
// import ConsentForm from './ConsentForm'
export default {
  name: 'MenberService',
  components: {
    DealerInformation,
    GuideFour
    // ConsentForm
  },
  data () {
    return {
      isShowLoadging: false,
      isShowFour: false,
      ProductId: '',
      map: {},
      userName: {},
      LINEMid: ''
    }
  },
  async mounted () {
    this.ProductId = storage.getItem('CustProdId')
    if (this.ProductId) {
      this._getMaintenanceDistribution()
    }
    this.userName = storage.getItem('userName')
    this.LINEMid = this.getUserLineId(this.$route)
    console.log('LINEMid', this.LINEMid)
  },
  methods: {
    _getMaintenanceDistribution () {
      this.isShowLoadging = true
      const { ProductId } = this
      getMaintenanceDistribution({
        ProductId
      }).then(res => {
        this.map = res.data.Data
        this.isShowLoadging = false
      })
    },
    // clickTheQuery () {
    //   // TODO: 上線開放點數查詢
    //   // window.alert('此功能目前開發中，請敬請期待')
    //   this.$router.push({ name: 'theQuery', query: { mid: this.LINEMid } })
    //   // console.log(this.$router)
    // },
    clickServiceApply () {
      const base64 = btoa(this.userName.CustId)
      console.log('custId', base64)
      actionLog({
        userId: this.LINEMid,
        action: 'click-service-application',
        cid: this.userName.CustId
      })
      window.location.href = `https://service.sakura.com.tw/service.aspx?custId=${base64}`
    },
    clickPersonalInformation () {
      this.$router.push({ name: 'personalInformation', query: { mid: this.LINEMid } })
    },
    clickTermsAndConditions () {
      this.$router.push({ name: 'termsAndConditions', query: { mid: this.LINEMid } })
    },
    clickServiceHistory () {
      actionLog({
        userId: this.LINEMid,
        action: 'click-service-history',
        cid: this.userName.CustId
      })
      this.$router.push({ name: 'serviceHistory', query: { mid: this.LINEMid } })
    },
    clickWaterQuantity () {
      this.$router.push({ name: 'waterQuantity', query: { mid: this.LINEMid } })
    },
    clickExit () {
      this.isShowFour = true
    },
    confirmClick () {
      this.$router.push({ name: 'Login' })
    },
    noClick () {
      this.isShowFour = false
    }
  }
}
</script>

<style scoped lang="scss">
@import '../assets/styles/mixin.scss';
.name>h1 {
    width: 120px;
}
.icon-list>.el-col {
    padding-top: 7.5px;
    padding-bottom: 7.5px;
}
.contents {
  .title {
    margin-bottom: 4rem;
    text-align: center;
    color: #fff;
    h1 {
      line-height: 4.4rem;
      font-size: 3.1rem;
      font-weight: 400;
      color: #131313;
    }
    span {
      line-height: 1.7rem;
      font-size: 1.2rem;
      font-weight: 200;
      color: #9B9B9B;
    }
  }
  .el-row {
    margin-top: 2rem;
    .el-card {
      max-height: 18.7rem;
      padding: 2rem 0;
      border-radius: 0rem;
      text-align: center;
      /deep/ .el-card__body {
         @include flex()
      }
      cursor: pointer;
      .name {
        margin-left: 1.6rem;
        text-align: center;
        h1 {
          line-height: 2.6rem;
          font-size: 1.8rem;
          font-weight: 400;
          color: #3D3D3D;
        }
        p{
          line-height: 1.7rem;
          font-size: 1.2rem;
          color: #BDBDBD;
        }
      }
    }
    .card-2 {
      span {
        //@include bgImg(3.3rem,3.8rem, '../assets/icon/card_giftcard_black_24dp.svg',3.3rem)
      }
    }
    .card-3 {
      span {
        //@include bgImg(3.7rem,3.8rem, '../assets/icon/support_agent_black_24dp.svg',3.7rem)
      }
    }
    .card-4 {
      span {
        //@include bgImg(3.7rem,3.8rem, '../assets/icon/person_outline_black_24dp.svg',3.7rem)
      }
    }
    .card-5 {
      span {
        //@include bgImg(3.7rem,3.8rem, '../assets/icon/Bitmap.svg',3.7rem)
      }
    }
    .card-6 {
      span {
        //@include bgImg(3.7rem,3.8rem, '../assets/icon/manage_search_black_24dp.svg',3.7rem)
      }
    }
    .card-7 {
      span {
        //@include bgImg(3.7rem,3.8rem, '../assets/icon/local_drink_black_24dp.svg',3.7rem)
      }
    }
  }
  .btn {
    width: 16rem;
    height: 4rem;
    @include flex();
    margin-top: 12rem;
    margin-left: auto;
    margin-right: auto;
    font-size: .6rem;
    font-weight: 700;
    color: #fff;
    background: #c8161d;
    //border-radius: 1.6rem;
    cursor: pointer;
  }
@media screen and (min-width:992px) and (max-width:1200px) {
    .el-row {
      .el-card {
        height: 11.1rem;
        display: flex;
        align-items: center;
        // padding-left: 1rem;
        /deep/ .el-card__body {
        margin-left: 1rem;
        padding: 1.4rem;
      }
      .name {
        margin-left: .8rem;
      }
    }
  }
}
@media screen and (max-width: 991px) {
  .el-row{
    .el-card {
      margin-bottom: 1.5rem;
    }
    // .card-2 {
    //  /deep/ .el-card__body {
    //     padding-left: 0;
    //   }
    //   .name {
    //     width: 8rem;
    //   }
    // }
  }
}
}
</style>
